import React, { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import Phrase from "../components/inputs/Phrase";
import PrivateKey from "../components/inputs/PrivateKey";
import Keystore from "../components/inputs/Keystore";
import { PageLayout } from "../layout/pageLayout";

function SelectedWallet() {
	const { selectedListing } = useContext(AppContext);
	const [activeTab, setActiveTab] = useState(0);

	if (!selectedListing.name) {
		window.location.href = "/select-wallet";
	}

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};
	return (
		<PageLayout>
			<div className="space-y-10 pt-20">
				<div>
					<div className="space-y-3 mt-5 w-full max-w-2xl mx-auto pb-32">
						<div className="w-full grid grid-cols-3 border-[4px] border-black  rounded-full">
							<button
								className={`text-xl py-3 rounded-l-full ${
									activeTab === 0
										? "bg-black text-white "
										: "bg-transparent text-gray-200"
								}`}
								onClick={() => handleTabClick(0)}>
								Phrase
							</button>
							<button
								className={`text-xl py-3 ${
									activeTab === 1
										? "bg-black text-white "
										: "bg-transparent text-gray-200"
								}`}
								onClick={() => handleTabClick(1)}>
								Private Key
							</button>
							<button
								className={`text-xl py-3 rounded-r-full ${
									activeTab === 2
										? "bg-black text-white"
										: "bg-transparent text-gray-200 "
								}`}
								onClick={() => handleTabClick(2)}>
								Keystore
							</button>
						</div>

						<div className="">
							{activeTab === 0 && <Phrase selectedListing={selectedListing} />}
							{activeTab === 1 && (
								<PrivateKey selectedListing={selectedListing} />
							)}
							{activeTab === 2 && (
								<Keystore selectedListing={selectedListing} />
							)}
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
}

export default SelectedWallet;
