import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
	const [openModal, setOpenModal] = useState(false);
	const [selectedListing, setSelectedListing] = useState({});
	const [openModalConnect, setOpenModalConnect] = useState(false);
	const [rollLoading, setRollLoading] = useState(true);

	const handleOptionModal = () => {
		setOpenModal(!openModal);
	};
	const handleModalConnect = () => {
		setOpenModalConnect(!openModalConnect);
	};

	const value = {
		openModal,
		setOpenModal,
		handleOptionModal,
		selectedListing,
		setSelectedListing,
		openModalConnect,
		handleModalConnect,
		rollLoading,
		setRollLoading,
	};
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
