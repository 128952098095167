import React from "react";

function CustomBtn({ size, color, children, ...props }) {
	return (
		<button
			className={`h-[52px] rounded-full focus:shadow-sm text-white font-bold capitalize ${
				color === "dark" ? "bg-black" : "bg-[#ac8eeb]"
			}  ${
				size === "sm"
					? "px-3"
					: size === "lg"
					? ""
					: size === "xl"
					? "w-full"
					: ""
			}  `}
			{...props}>
			{children}
		</button>
	);
}

export default CustomBtn;
