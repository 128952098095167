import React from "react";

function ModalLayout({ children }) {
	return (
		<div className="z-[2000] fixed bg-black bg-opacity-85 h-screen w-full inset-0 flex items-center justify-center px-4">
			<div className="bg-[#bac3d6] rounded w-full max-w-xl mx-auto p-4">
				{children}
			</div>
		</div>
	);
}

export default ModalLayout;
