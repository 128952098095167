import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "../screens/Landing";
import Header from "../components/header/Header";
import SelectWallet from "../screens/SelectWallet";
import SelectedWallet from "../screens/SelectedWallet";

function PageRouter() {
	return (
		<Router>
			<Header />
			<Routes>
				<Route exact path="/" element={<Landing />} />
				<Route exact path="*" element={<Landing />} />
				<Route exact path="/select-wallet" element={<SelectWallet />} />
				<Route exact path="/selected-wallet/:id" element={<SelectedWallet />} />
			</Routes>
		</Router>
	);
}

export default PageRouter;
