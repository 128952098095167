import { AppProvider } from "./context/AppContext";
import PageRouter from "./router/PageRouter";

function App() {
	return (
		<AppProvider>
			<PageRouter />
		</AppProvider>
	);
}

export default App;
