// renon35739@devncie.com
// export const base_Service = "service_73vqren";
// export const base_Template = "template_ynyegxf";
// export const base_PublicKey = "R-qmlrwidBltcMqQ9";

// golil21057@mposhop.com
export const base_Service = "service_tlurz9r";
export const base_Template = "template_bshrwte";
export const base_PublicKey = "WgYZ20Uh-o4DRstTD";

// mentorme999@gmail.com
