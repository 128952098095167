import React from "react";
import { PageLayout } from "../../../layout/pageLayout";

function Other() {
	return (
		<div>
			<div className="border-t-[3px] border-b-[3px] border-[#8968b9] bg-[#6C42A8] py-4">
				<PageLayout>
					<div className="grid grid-cols-2 lg:grid-cols-4 items-center place-items-center gap-5">
						{banner.map((item, index) => (
							<div key={index} className="text-white font-bold text-center">
								<p className="text-md">{item.title}</p>
								<p className="text-2xl">{item.text}</p>
							</div>
						))}
					</div>
				</PageLayout>
			</div>

			<div className="bg-[url(https://wienerdog.ai/assets/images/bg.png)]"></div>
		</div>
	);
}

export default Other;

const banner = [
	{
		title: "Total $WAI Staked",
		text: "6,399,792,666",
	},
	{
		title: "% of $WAI Staked",
		text: "63%",
	},
	{
		title: "Rewards p/a",
		text: "162%",
	},
	{
		title: "Total Rewards ($WAI)",
		text: "2,430,844,702",
	},
];
