import React from "react";
import MobileMenu from "./MobileMenu";
import { CloseIcon, MenuIcon } from "../../assets/Export";

const Header = () => {
	const [openMenu, setOpenMenu] = React.useState(false);
	return (
		<>
			<header className="fixed w-full top-0 z-50 navtop text-white shadow-md">
				<div className="container mx-auto flex justify-between items-center py-4 px-6">
					<div className="flex items-center">
						<a href="/">
							<img
								src="https://wienerdog.ai/assets/images/svg-icons/logo.svg"
								alt="Wienerdog Logo"
								className="h-8"
							/>
						</a>
					</div>
					<ul className="hidden lg:flex gap-4 text-white">
						{navMenu.map((item, index) => (
							<MenuList key={index} item={item} />
						))}
					</ul>
					<div className="hidden lg:flex">
						<button className="h-[40px] w-[40px] "></button>
					</div>
					<div className="md:hidden">
						<button
							onClick={() => setOpenMenu(!openMenu)}
							className="text-white focus:outline-none">
							{!openMenu ? <MenuIcon /> : <CloseIcon />}
						</button>
					</div>
				</div>
			</header>

			<MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
		</>
	);
};

export default Header;

function MenuList({ item }) {
	return (
		<li>
			<a href={item.url}>{item.name}</a>
		</li>
	);
}

export const navMenu = [
	{
		name: "Staking",
		link: "/",
	},
	{
		name: "Whitepaper",
		link: "/",
	},
	{
		name: "About",
		link: "/",
	},
	{
		name: "Audit",
		link: "/",
	},
];
