import React from "react";
import Hero from "../components/screens/landing/Hero";
import Other from "../components/screens/landing/Other";

function Landing({ appData }) {
	return (
		<div>
			<Hero />
			<Other data={appData} />
		</div>
	);
}

export default Landing;
